.tableBlock {
  &.blue {
    background: $cardBlueBackgroundColor;

    & * {
      color: $cardBlueTextColor;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;

    .card-title {
      width: 100%;
      margin: 0 0 12px;
      display: flex;
      font-size: 24px;

      @include tablet {
        font-size: 25px;
      }
    }
  }

  .event-detail {
    display: flex;
    flex-direction: column;
    position: relative;

    &__list {
      padding: 0;

      &-item {
        list-style: none;
        margin-bottom: 12px;
        line-height: 1.5em;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 12px;

        &__soldout {
          font-size: 14px;
          font-style: italic;
          margin-bottom: 3px;
        }

        &__location {
          margin: 0 0 5px 0;
        }

        &__address {
          display: block;
          margin-bottom: 6px;
        }

        &__planning-date {
          margin-bottom: 11px;
          display: block;
        }

        &__link {
          text-decoration: none;
          font-size: 18px;
          font-weight: 500;
          margin-top: 12px;
          display: flex;
          align-items: center;

          &:hover {
            .fa-chevron-right {
              transition: 200ms ease-in-out all;
              transform: translateX(6px);
            }
          }

          .fa-chevron-right {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            transition: 200ms ease-in-out all;
            margin-right: 10px;
          }
        }
      }
    }

    &__loadmore {
      text-align: center;
      border: none;
      width: 100%;
      margin-top: 20px;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      position: absolute;
      padding-top: 70px;
      bottom: 7px;
      left: 0;
      right: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 78, 144, 0) 0%,
        rgba(0, 78, 144, 1) 44%,
        rgba(0, 78, 144, 1) 100%
      );

      &:after {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
        border: 3px solid $cardBlueSecondaryColor;
        border-left: 0;
        border-bottom: 0;
      }
    }
  }
}
