.centerIntroBlock-holder {
  display: flex;
  justify-content: center;
}

.introBlock {
  display: flex;
  flex-direction: column;
  position: relative;

  .intro-container {
    color: $primaryColor;

    p {
      color: $primaryColor;
      font-weight: 700;
    }
  }

  .intro-list {
    list-style: none;
    padding-left: 0;
  }

  .intro-title {
    margin-top: 0;
  }

  .intro-body {
    a {
      text-decoration: underline;
    }

    ul {
      padding-left: 20px;
    }

    ul, li {
      list-style: disc;
    }

    .cta {
      a {
        text-decoration: none;
        width: fit-content;
      }
    }

    .link {
      text-decoration: none;
    }
  }

  .img {
    width: 100%;
  }

  .share-icons__list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 10px 0 0 0;

    a {
      font-size: 30px;
      margin-right: 14px;
    }
  }
}
