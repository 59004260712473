.footer {
  margin-top: 60px;
  color: white;
  background: {
    color: $primaryColor;
    image: icon("khn-crown-footer.png");
    repeat: no-repeat;
    size: 220px;
    position: 0 calc(100% - 60px);
  }
  strong {
    margin-bottom: 1em;
    color: white;
    display: block;
  }
  &__title {
    flex: 1 100%;
  }
  &__holder {
    list-style: none;
    display: flex;
    width: calc(100% + 30px);
    margin: 0;
    margin-left: -15px;
    padding: 0;
    @include phone {
      flex-wrap: wrap;
      width: 100%;
    }
  }
  &__column {
    flex: 2;
    padding: 0 15px;
    &--big {
      display: flex;
      flex: 3;
      align-content: flex-start;
      flex-wrap: wrap;
      padding-right: 0;
      & .footer__content {
        padding-right: 16px;
      }
      @include phone {
        display: block;
      }
    }
    @include phone {
      flex: 1 0 224px;
      padding: 20px 40px;
    }
  }
  &__content {
    flex: 1;
    padding: 0;
    list-style: none;
  }
  &__item {
    line-height: 1.7;
    min-height: 1.7em;
    color: white;
    font-size: 14px;
    a {
      color: white;
    }
  }
  &__item-icon {
    margin-right: 4px;
    display: inline-block;
  }
  @include phone {
    background-image: none;
  }

  .top {
    padding: {
      top: 70px;
      bottom: 40px;
    }

    @include phone {
      padding-top: 40px;
    }
    .footer-logo {
      width: 110px;
      height: 140px;
      margin-right: 60px;
      @include phone {
        display: none;
      }
    }
  }

  .bottom {
    padding: 20px 0;
    width: 100%;
    background-color: white;
    color: $primaryColor;

    .bottom-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include phone {
        flex-direction: column;
        .left,
        .right {
          width: 100%;
          text-align: left;
          margin-bottom: 20px;
        }
      }
      @include tablet-portrait {
        .left {
          flex-wrap: wrap;
          small {
            width: 100%;
            padding: 12px 6px;
            border-bottom: 1px solid $sectionBorderColor;

            &:last-child {
              border-bottom: 0;
            }
          }
          .footer-link {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    .left {
      display: flex;
      .footer-link {
        text-decoration: none;
        color: $primaryColor;
        &:first-of-type {
          margin-right: 1em;
          &::after {
            content: "|";
            margin-left: 1em;
          }
        }
        &:hover {
          color: skyblue;
          transition: 0.2s;
        }
      }
    }
    .right {
      text-align: right;
    }
  }
}
