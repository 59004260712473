// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.cta {
  font-size: 16px;
  line-height: 1.8;

  a {
    display: block;
    margin: 26px 0 0 0;
    padding: 16px 12px;
    font-size: 15px;
    line-height: 1.8;
    color: $cardButtonTextColor;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: $colorRed;

    &:hover {
      transition: 0.1s;
      background-color: $colorBlue;
    }
  }

  &.full-width {
    width: 100%;
    a {
      width: 100% !important;
    }
  }
}

.button-primary,
a.button-primary {
  @extend .t-button-primary;

  display: inline-flex;
  background: $colorRed;
  padding: 1.25rem;
  color: $colorWhite;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius;
  transition: background 0.3s ease-out;

  @include on-event {
    text-decoration: none !important;
    background: $colorBlue;
  }
}

.button-secondary,
a.button-secondary {
  @extend .t-button-secondary;
}
