.banner:not(.glide .banner) {
  // overwrites for the paddings in _grid.scss so the banner is the full width of the page
  width: calc(100% + 60px);
  margin-left: -30px;

  @include phone {
    width: calc(100% + 18px);
    margin-left: -9px;
  }
  @media (min-width: 1270px) {
    width: calc(100% + ((45vw - 555px) * 2));
    margin-left: calc((45vw - 555px) * -1);
  }
}
