// Navigation
.navigation {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  background: $colorWhite;
  z-index: 100;

  .navigation-desktop {
    display: none;

    @include desktop {
      display: block;
    }
  }

  .navigation-mobile {
    display: block;

    @include desktop {
      display: none;
    }
  }

  .navigation-wrapper {
    padding: 16px 0 18px;
    border-bottom: 1px solid $colorBlue25;
    transition: padding 0.3s ease-in-out;
  }

  .navigation-logo {
    height: 64px;
    transition: height 0.3s ease-in-out;

    @include desktop {
      height: 80px;
    }
  }

  .navigation-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    transition: margin 0.3s ease-in-out;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .navigation-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: relative;

    .search-button {
      border: 1px solid #D0D0D0;
      background: #F7F7F7;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;
      border-radius: 8px;

      .search-icon {
        display: flex;
      }
    }

    .search-form {
      pointer-events: none;
      transition: all .5s;
      opacity: 0;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      max-width: 1050px;
      margin-left: auto;

      &.search-form-open {
        opacity: 1;
        pointer-events: all;

        form {
          width: 100% !important;
        }
      }

      form {
        height: 100%;
        width: 0;
        margin: 0 auto;
        display: block;
        transition: all .5s;

        .search-wrapper {
          height: 100%;
          display: block;
          position: relative;

          input {
            height: 100%;
            border-color: #000;
            color: #000;
            background: #fff;
            width: 100%;

            &::-webkit-search-cancel-button,
            &::-webkit-search-decoration {
              appearance: none !important;
            }
          }
        }
      }

      button.search-form-close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .divider {
      height: 24px;
      margin: 0 20px;
    }
  }

  .navigation-item {
    border-bottom: 1px solid $colorBlue25;
    padding: 16px 0;

    @include desktop {
      border-bottom: 0;
      padding: 0;

      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }

  .navigation-list {
    display: block;
    margin: 0;

    @include desktop {
      display: flex;
      align-items: center;

      .divider {
        height: 100%;
        padding: 15px 0;
        margin-left: 20px;
      }
    }
  }

  // Navigation Mobile
  .navigation-mobile {
    .content-wrapper {
      position: relative;
      background: $colorWhite;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      z-index: $zIndexNavigation;
      transition: padding 0.3s ease-in-out;
    }

    .content {
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 0;
      gap: 1rem;
    }

    .divider {
      height: 100%;
      padding: 0 16px;

      &::after {
        border: 1px solid $colorBlue;
      }
    }
  }

  .divider {
    position: relative;
    display: block;
    width: 1px;
    padding: 15px 0;

    &::after {
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      position: absolute;
      border: 1px solid $colorBlue50;
    }
  }

  // Navigation Modal
  .navigation-modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zIndexModal;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;
    height: 100dvh;
    width: 100%;

    background: $colorWhite;

    .wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navigation-content {
      border-top: 1px solid $colorBlue25;
    }

    .navigation-bottom {
      margin-top: 32px;
      padding-bottom: 16px;

      .navigation-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        text-align: center;
      }

      .navigation-button {
        width: 100%;
        margin-bottom: 10px;
      }

      .button-primary {
        width: 100%;
      }

      .navigation-item {
        width: 50%;
        border-bottom: 0;
      }
    }
  }

  // isScrolled style
  &.is-scrolled {
    .navigation-logo {
      height: 52px;

      @include desktop {
        height: 65px;
      }
    }

    .navigation-wrapper {
      padding-bottom: 12px;
    }

    .navigation-container {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .navigation-mobile {
      .content-wrapper {
        padding-bottom: 12px;
      }
    }
  }
}
