.khn-search-row {
  margin: 0 9px;

  @include until-desktop {
    margin: 0 -9px;
    padding: $flexboxgrid-gutter-width;
  }
}

.search-box {
  position: relative;
  display: flex;
  height: 48px;
  margin-bottom: 8px;

  //removes blue clear button
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  .search-box__input {
    font-size: 16px;
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: white;
    padding-left: 23px;
    border: solid 2px #c4c4c4;
    color: $primaryColor;
    border-radius: 30px;
    &::-ms-clear {
      display: none;
    }
    &::-webkit-search-cancel-button {
      margin-bottom: 1px;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 10px #ccc;
      color: #333;
      + .search-icon {
        opacity: 1;
      }
    }
  }
  .search-icon {
    opacity: 0.7;
    position: absolute;
    height: 16px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.khn-search--container {
  margin-top: 200px;
  width: 100%;

  @include until-desktop {
    margin-top: 124px;
  }
}
