.relatedNews {
  .newsOverviewList {
    &__filters {
      p {
        margin-bottom: 30px;
      }

      &__list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 100%;
          margin: 0.25rem 0;

          @include desktop {
            width: 50%;
          }

          .checkbox-container {
            position: relative;

            .checkbox {
              label {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 30px;
                font-weight: 500;

                &:hover {
                  .checkmark {
                    background-color: $cardBackgroundColor;
                  }
                }

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;

                  &:checked {
                    & + .checkmark {
                      background-color: $primaryColor;

                      &:after {
                        content: "";
                      }
                    }
                  }
                }

                .checkmark {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  height: 18px;
                  width: 18px;
                  border: 1px solid $primaryColor;
                  border-radius: 3px;
                  box-sizing: content-box;

                  &:after {
                    position: absolute;
                    left: 5px;
                    top: 2px;
                    width: 5px;
                    height: 8px;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                    box-sizing: content-box;
                  }
                }

                .title {
                  position: relative;
                  display: flex;
                  align-items: center;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      &__list-button {
        width: 100%;
        border-top: 1px solid $cardBlueBorderColor;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding-top: 20px;
        margin-top: 20px;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: none;
        cursor: pointer;

        @include desktop {
          align-items: center;
          justify-content: center;
        }

        @include on-event {
          i {
            transform: none;
          }
        }

        i {
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          transition: 200ms ease-in-out all;
          font-size: 16px;
          line-height: 18px;
          margin-right: 12px;
        }

        span {
          width: max-content;
          margin: 0;
        }
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        width: 100%;
        margin-top: 24px;

        @include desktop {
          width: calc(50% - 12px);
        }

        &--small {
          width: calc(#{100% / 3} - 18px);

          @include until-desktop {
            width: calc(#{100% / 2} - 18px);
          }

          @include until-tablet {
            width: 100%;
          }
        }
      }

      &-button {
        margin: 30px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        cursor: pointer;

        @include on-event {
          i {
            transform: rotate(90deg);
          }
        }

        i {
          margin-right: 18px;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          transition: 200ms ease-in-out all;
          font-size: 16px;
          line-height: 18px;
          transform: rotate(90deg);
          margin-top: 6px;
        }
      }
    }
  }
}
