$phone-small-min: 320px; // iPhone 5
$phone-medium-min: 360px; // Galaxy S5
$phone-large-min: 375px; // iPhone 6
$phone-xlarge-min: 414px; // iPhone 6 Plus
$tablet-small-min: 600px;
$tablet-min: 768px;
$desktop-min: 992px;
$desktop-wide-min: 1200px;
$desktop-xwide-min: 1366px;
$desktop-xxwide-min: 1600px;
$full-hd-min: 1920px;
$phone-ls-min: 568px; // iPhone 5
$phone-medium-ls-min: 640px; // Galaxy S5
$phone-large-ls-min: 667px; // iPhone 6
$phone-xlarge-ls-min: 736px; // iPhone 6 Plus
$tablet-small-ls-min: 960px;
$tablet-ls-min: 1024px; // iPad 1
$tablet-large-ls-min: $desktop-xwide-min; // iPad Pro 12"
$phone-max: ($tablet-small-min - 1);
$phone-large-max: ($phone-xlarge-min - 1);
$tablet-small-max: ($tablet-min - 1);
$tablet-max: ($desktop-min - 1);
$desktop-max: ($desktop-wide-min - 1);
$phone-medium: "(min-width:#{$phone-medium-min})";
$phone-large: "(min-width:#{$phone-large-min})";
$phone-xlarge: "(min-width:#{$phone-xlarge-min})";
$phone-ls: "(min-width:#{$phone-ls-min})";
$tablet-small: "(min-width:#{$tablet-small-min})";
$tablet: "(min-width:#{$tablet-min})";
$desktop: "(min-width:#{$desktop-min})";
$desktop-wide: "(min-width:#{$desktop-wide-min})";
$desktop-xwide: "(min-width:#{$desktop-xwide-min})";
$desktop-xxwide: "(min-width:#{$desktop-xxwide-min})";
$full-hd: "(min-width:#{$full-hd-min})";
$phone-small-medium-only: "(max-width:#{$phone-large-min - 1})";
$phone-large-only: "(min-width:#{$phone-large-min}) and (max-width:#{$phone-xlarge-min - 1})";
$phone-xlarge-only: "(min-width:#{$phone-xlarge-min}) and (max-width:#{$tablet-small-min - 1})";
$phone-large-xlarge-only: "(min-width:#{$phone-large-min}) and (max-width:#{$tablet-small-min - 1})";
$phone-only: "(max-width:#{$phone-max})";
$phone-tablet-small-only: "(max-width:#{$tablet-small-max})";
$mobile-only: $phone-tablet-small-only;
$phone-tablet-only: "(max-width:#{$tablet-max})";
$max-tablet-small: $phone-tablet-small-only;
$phone-ls-only: "(min-width:#{$phone-ls-min}) and (max-width:#{$phone-max})";
$phone-ls-tablet-small-only: "(min-width:#{$phone-ls-min}) and (max-width:#{$tablet-small-max})";
$tablet-small-only: "(min-width:#{$tablet-small-min}) and (max-width:#{$tablet-small-max})";
$tablet-small-tablet-only: "(min-width:#{$tablet-small-min}) and (max-width:#{$tablet-max})";
$tablet-only: "(min-width:#{$tablet-min}) and (max-width:#{$tablet-max})";
$tablet-desktop-only: "(min-width:#{$tablet-min}) and (max-width:#{$desktop-max})";
$desktop-only: "(min-width:#{$desktop-min}) and (max-width:#{$desktop-max})";
$until-phone-xlarge: "(max-width:#{$phone-large-max})";
$until-tablet-small: "(max-width:#{$phone-max})";
$until-tablet: "(max-width:#{$tablet-small-max})";
$until-desktop: "(max-width:#{$tablet-max})";

@mixin mq($width) {
    @media only screen and (min-width: #{$width}) {
        @content;
    }
}

@mixin mq-max($width) {
    @media only screen and (max-width: #{$width}) {
        @content;
    }
}

@mixin phone-medium {
    @media #{$phone-medium} {
        @content;
    }
}

@mixin phone-large {
    @media #{$phone-large} {
        @content;
    }
}

@mixin phone-xlarge {
    @media #{$phone-xlarge} {
        @content;
    }
}

@mixin phone-only {
    @media #{$phone-only} {
        @content;
    }
}

@mixin phone-small-medium-only {
    @media #{$phone-small-medium-only} {
        @content;
    }
}

@mixin phone-large-only {
    @media #{$phone-large-only} {
        @content;
    }
}

@mixin phone-xlarge-only {
    @media #{$phone-xlarge-only} {
        @content;
    }
}

@mixin phone-large-xlarge-only {
    @media #{$phone-large-xlarge-only} {
        @content;
    }
}

@mixin tablet-small {
    @media #{$tablet-small} {
        @content;
    }
}

@mixin tablet-small-only {
    @media #{$tablet-small-only} {
        @content;
    }
}

@mixin mobile-only {
    @media #{$mobile-only} {
        @content;
    }
}

@mixin tablet {
    @media #{$tablet} {
        @content;
    }
}

@mixin tablet-only {
    @media #{$tablet-only} {
        @content;
    }
}

@mixin tablet-desktop-only {
    @media #{$tablet-desktop-only} {
        @content;
    }
}

@mixin desktop {
    @media #{$desktop} {
        @content;
    }
}

@mixin desktop-only {
    @media #{$desktop-only} {
        @content;
    }
}

@mixin desktop-wide {
    @media #{$desktop-wide} {
        @content;
    }
}

@mixin desktop-xwide {
    @media #{$desktop-xwide} {
        @content;
    }
}

@mixin desktop-xxwide {
    @media #{$desktop-xxwide} {
        @content;
    }
}

@mixin full-hd {
    @media #{$full-hd} {
        @content;
    }
}

@mixin until-phone-xlarge {
    @media #{$until-phone-xlarge} {
        @content;
    }
}

@mixin until-tablet-small {
    @media #{$until-tablet-small} {
        @content;
    }
}

@mixin until-tablet {
    @media #{$until-tablet} {
        @content;
    }
}

@mixin until-desktop {
    @media #{$until-desktop} {
        @content;
    }
}

@mixin phone-tablet-only {
    @media #{$phone-tablet-only} {
        @content;
    }
}

@mixin max-tablet-small {
    @media #{$max-tablet-small} {
        @content;
    }
}

@mixin if-grid {
    @supports (display: grid) {
        @content;
    }
}

@mixin if-not-grid {
    @supports not (display: grid) {
        @content;
    }
}
// CUSTOM QUERIES
@mixin mq($width1, $width2: 0) {
  @if $width2 > 0 {
    @media only screen and (min-width: #{$width1} and (max-width: #{$width2}) ) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$width1}) {
      @content;
    }
  }
}
