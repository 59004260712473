.error {
  display: flex;
  flex-wrap: wrap;
  margin: 200px 128px 0 128px;
  align-items: center;

  @include until-desktop {
    margin: 124px 18px 0 18px;
  }

  &--content {
    flex: 1;
  }
  &--title {
    font-family: $primaryFont;
    font-size: 54px;
    color: $secondaryColor;
    @include until-tablet {
      font-size: 32px;
    }
  }
  &--text {
    font-size: 27px;
    @include until-tablet {
      font-size: 18px;
    }
  }
  &--search {
    flex: 1 0 100%;
    margin-top: 40px;
  }

  &--image {
    img {
      height: 50vh;
    }
    @include until-tablet {
      display: none;
    }
  }
}

.searchBar {
  &--input {
    height: 48px;
    position: relative;
    input {
      font-size: 16px;
      flex: 1;
      height: 100%;
      width: 100%;
      background-color: #fff;
      padding-left: 23px;
      border: 2px solid $secondaryTextColor;
      color: $primaryColor;
      border-radius: 30px;
      &:focus {
        outline: none;
        box-shadow: 0 0 10px #ccc;
        color: #333;
        + .searchBar--input-icon {
          opacity: 1;
        }
      }
    }
    &-icon {
      opacity: 0.7;
      position: absolute;
      height: 16px;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--link {
    width: max-content;
    margin: 0 auto;
    a {
      font-size: 16px;
      font-weight: 700;
    }
    i {
      margin-top: 2px;
      margin-left: 5px;
      font-size: 14px;
    }
  }
}
