.modal-holder {
  position: fixed;
  top: 68px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:target,
  &.vue-popup {
    //active state
    visibility: visible;
    opacity: 1;
    z-index: 7;
  }
  .row {
    margin: 0;
    padding: 8vh 0;
  }
}

.modal {
  background: none;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  display: flex;
  max-height: 80vh;
  height: auto;
  width: 100%;

  &.popup {
    flex-direction: column;
  }

  &--image {
    width: 80px;
    margin: auto auto 0 0;
  }
  &--bottom {
    background-color: white;
    padding: 48px;
    text-align: center;
    overflow-y: auto;
  }
  &--content {
    background-color: $primaryColor;
    flex: 4;
    padding: 50px 48px 44px 48px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    * {
      color: white;
    }
  }
  &--form {
    background-color: white;
    flex: 8;
    padding: 50px 48px 44px 48px;
    overflow: auto;
  }
  &--close {
    position: absolute;
    text-decoration: none;
    top: 9px;
    right: 9px;
    background: #fff;
    border: 2px solid $primaryColor;
    color: $primaryColor;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    &:hover {
      color: white;
      text-decoration: none;
      background: $primaryColor;
    }
  }
}
