.search-wrapper {
  position: relative;

  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }

  input[type=search] {
    padding: 1rem 1rem 1rem 3rem;
    border-radius: 8px;
    border: 1px solid #D0D0D0;
    background-color: #F7F7F7;
    font-size: 17px;

    &::placeholder {
      color: #585858;
    }
  }
}
