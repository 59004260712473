.block--hero-carousel {
  height: unset;
  width: 100%;

  @media (min-width: #{$desktopDesignWidth +1}) {
    height: 632px;
    margin-bottom: -42px;
  }
  .hero-container {
    @media (min-width: #{$desktopDesignWidth + 1}) {
      height: 560px;
    }
  }
  @media (max-width: $desktopDesignWidth) {
    .hero-section {
      display: flex;
      flex-direction: row-reverse;
    }
    .img-container {
      width: calc(100% + 20px) !important;
      margin-right: -20px !important;
      margin-left: -33px !important;
    }
    .hero-container {
      width: 77%;
      margin-left: 0;
      margin-right: 0;
    }
    .title {
      font-size: 20px !important;
      &.compact,
      &.medium {
        font-size: 18px !important;
      }
    }
    .link {
      i {
        height: 12px;
        width: 12px;
        font-size: 10px;
        line-height: 12px;
      }
      span {
        font-size: 10px;
      }
    }
    .glide {
      margin-left: -2px !important;
    }
  }

  .glide {
    display: inline-flex;
    position: relative;
    margin-top: -2px; //fixes weird alignment with images
    height: 100%;
    overflow: hidden;
    max-width: 100vw;
    margin-bottom: -48px;
    .hero-row {
      margin: 0;
      width: 100%;
    }
  }
  .glide__controls {
    position: absolute;
    margin-left: 12px;
    bottom: 0;
    @media (min-width: #{$desktopDesignWidth + 1}) {
      bottom: 52px;
    }
    @include desktop {
      margin-left: 28px;
    }

    &--overide {
      .glide__bullet--active {
        background: black;
        opacity: 0.2;
      }
    }
  }
  .glide__bullet {
    background: green;
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px 0 0;
    background: black;
    opacity: 0.2;
    border: none;
    padding: 0;
    @include desktop {
      width: 16px;
      height: 16px;
      padding: 1px 6px;
    }
    &--force-active {
      background: $primaryColor;
      opacity: 1;
    }
    &--active {
      background: $primaryColor;
      opacity: 1;
    }
  }
  // .glide,
  .glide__slides,
  .glide__track {
    display: inline-flex;
    position: relative;
  }
  .glide__slides {
    backface-visibility: visible;
  }
  .glide__slide {
    position: relative;
  }
  .glide__track {
    max-width: 100vw;
  }
}

.block--news-carousel {
  padding: 20px 0 100px;
  background-color: #DAE8EF;

  .glide {
    @media(min-width: 900px) {
      margin-top: -4rem;
    }
  }

  .glide__arrows {
    margin-bottom: 2.5rem;

    @media(max-width: 900px) {
      justify-content: start !important;
    }

    .glide__arrow {
      display: flex;

      &:hover {
        background: #fff;
      }
    }

    .glide-arrow {
      background-color: #fff;
      padding: 0.25rem 1rem;
    }
  }

  .featured {
    color: $colorBlue;
    margin-bottom: 0.75rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
}

.block--news-carousel,
.accomplishment-carousel {
  .glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  .glide * {
    box-sizing: inherit;
  }

  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
  }

  .glide__slides--dragging {
    user-select: none;
  }

  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  .glide__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .glide__arrow {
      border-radius: 999px;
      padding: 0.25rem 1rem;
      transition: all .3s;
    }

    .glide__arrow--right {
      background-color: white;
    }
  }

  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none;
  }

  .glide--rtl {
    direction: rtl;
  }

  .news-slide {
    position: relative;
    display: block;
    height: 380px;
    border-radius: 8px;
    font-family: "Ubuntu", sans-serif;
    overflow: clip;
    text-decoration: none;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s;
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 1.5rem;
      color: #fff;
      background: linear-gradient(0deg, rgba(0, 0, 0, .3), transparent);

      .date {
        font-weight: 500;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0.5rem 0.75rem;
        border-radius: 4px;
        align-self: start;
      }

      &:hover .name {
        transform: translateY(-8px);
      }

      .name {
        position: relative;
        font-size: 1.5rem;
        font-weight: 500;
        transition: all .5s;
      }
    }
  }
}
