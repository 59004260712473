.membership-slider {
  background: #01508A;
}

.testimonials {
  overflow: hidden;
  color: white;

  .col, .col-6 {
    box-shadow: unset;
    background-color: unset;
    height: 100%;
  }

  &__glide {
    position: relative;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  &__button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    width: 56px;
    height: 32px;
    transition: all 200ms ease;
    &:hover {
      background-color: white;
      color: $primaryColor;
    }
  }

  .glide__slides {
    display: flex;
  }
}

.testimonial {
  &__wrapper {
    width: 100%;
    @media (min-width: $desktopDesignWidth) {
      width: 50%;
    }
  }
  &__intro {
    font-weight: 500;
    font-size: 20px;
  }
  &__image {
    width: 96px;
    height: 96px;
    border-radius: 100%;
    display: block;
    object-fit: cover;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    height: 100%;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    @media (min-width: $desktopDesignWidth) {
      padding: 60px;
    }
  }
  &__thumbnail {
    display: flex;
    width: 100%;
    height: 100%;
    @media (min-width: $desktopDesignWidth) {
      width: 50%;
    }

      img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  &__author {
    align-items: center;
    gap: 20px;
  }
  &__slide {
    line-height: 1.8;
    display: flex;
    @media (max-width: $desktopDesignWidth) {
      flex-direction: column;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 20px;
    color: #DAE8EF;
  }
  &__title {
    font-size: 18px;
    display: block;
  }
  &__quote {
    display: block;
    line-height: 28px;
    > p {
      color: #ffffff;
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (min-width: $desktopDesignWidth) {
      width: 50%;
    }
  }
}
