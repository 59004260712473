.heroBlock {
  box-shadow: none;
  width: 100%;
  overflow: hidden;
  margin-top: 80px;

  @include desktop {
    overflow: visible;
  }

  .hero-section {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .img-container {
      width: calc(100% - 50px);
      margin: 0;
      margin-left: 60px;
      margin-bottom: 20px;
      height: 300px;

      @include desktop {
        height: 700px;
        width: 50%;
        margin: 0;
        position: absolute;
        right: 0;
      }

      .skewed {
        overflow: hidden;
        width: 100%;
        padding-left: 26px;
        height: 100%;
        display: block;

        @include desktop {
          padding-left: 61.5px;
        }

        .skewed__body {
          width: calc(100% + 60px);
          height: 300px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          transform: skew(-10deg);
          border-radius: 30px;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;

          @include desktop {
            width: 57vw;
            height: 700px;
          }

          .skew-compensation {
            transform: skew(10deg);
            width: 100%;
            height: 100%;
            margin-left: -72px;

            @include tablet {
              width: calc(100% + 160px);
              margin-left: -160px;
            }

            @include desktop {
              width: 100%;
              margin-left: -60px;
            }

            .skewed__image {
              position: absolute;
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              background: 50%;
              background-color: #eff5f8;
            }
          }
        }
      }
    }
  }

  .hero-container {
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 10px;

    @include desktop {
      height: 400px;
      margin-bottom: 0;
      width: 53%;
      padding: 0 25px !important;
      @include fluid-from-design(margin-left, 10px, 30px);
      @include fluid-from-design(margin-right, 10px, 30px);
      @media (min-width: 1270px) {
        margin-left: calc(45vw - 555px);
        margin-right: calc(45vw - 555px);
      }
    }
    &--without-image {
      height: auto;
      padding-top: 48px !important;
      @include until-desktop {
        margin-left: 0;
        margin-right: 0;
      }
      @include desktop {
        padding-top: 72px !important;
      }
      .welcome-msg {
        padding: 0 !important;
      }
    }

    .welcome-msg {
      color: #004e90;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 12px;
      width: 100%;
      max-width: 35em;
      height: auto !important;
      margin-left: 0 !important;
      margin-top: 12px;

      @include desktop {
        width: 85%;
        height: calc(100% - 80px);
        padding: 0;
        margin: 0;
      }

      .subtitle {
        font-size: 13px;
        margin: 0;

        @include desktop {
          font-size: 21px;
        }
      }

      .title {
        font-size: 32px;
        margin: 0.5rem 0 0;
        color: #db1546;
        font-weight: 700;
        font-family: 'Ubuntu', sans-serif;
        hyphens: auto;

        &.compact {
          font-size: 20px;
          @include tablet {
            @include fluid-from-design(font-size, 20px, 32px);
          }
          @include desktop {
            font-size: 32px;
          }
        }
        &.medium {
          font-size: 24px;
          @include tablet {
            @include fluid-from-design(font-size, 24px, 40px);
          }
          @include desktop {
            font-size: 40px;
          }
        }

        @include tablet {
          font-size: 40px;
        }

        @include desktop {
          font-size: 48px;
          margin: 6px 0 0 0;
        }
      }

      .second-subtitle {
        margin: 8px 0 0;
        font-size: 18px;
      }
    }
  }
  .page--postdate {
    font-size: 14px;
    margin-top: 16px;
    color: $primaryTextColor;
  }
}
