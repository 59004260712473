@include desktop {
  .grid-list > .block > .block--content > .card-content > .card__slot > .card-link > .card-link-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 25px;
  }
}

.linkBlock {
  &.blue {
    background: $cardBlueBackgroundColor;

    span {
      color: $cardBlueTextColor;

      &:hover {
        color: $cardBlueTextHoverColor;
      }
    }

    h3 {
      color: $cardBlueTextColor;
    }

    path {
      fill: $cardBlueTextColor;
    }
  }

  &.borderless {
    box-shadow: none;
    background: transparent;
    padding: 0;
    padding-bottom: 12px;
    height: unset;
    .block,
    .block--content,
    .card-content {
      height: 100%;
    }
  }

  &.small-title {
    .block {
      .block--content {
        .card-content {
          .card-header {
            .card-title {
              font-size: 20px;
              word-break: break-all;
            }
          }
        }
      }
    }
  }

  &.top-image {
    .block {
      .block--content {
        .card-content {
          .card-img-container {
            width: calc(100% + 4em);
            margin-left: -2em;
            margin-top: -2em;
          }
        }
      }
    }
  }

  .linkBlock-block {
    height: 100%;
  }

  .block--content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    flex-grow: 1;

    .card-header {
      display: flex;
      justify-content: space-between;

      &.hide {
        display: none;
      }

      .card-title {
        width: 100%;
        margin: 0 0 12px;
        display: flex;
        font-size: 24px;

        @include tablet {
          font-size: 25px;
        }

        &.top-image {
          padding-top: 30px;
        }
      }
    }

    .card-img-container {
      min-height: 190px;
      width: 100%;
      overflow: hidden;
      position: relative;

      .card-img-container__img {
        position: relative;
        height: 200px;
        transition: all 0.2s ease-in-out;

        img {
          border-radius: 6px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .card__slot {
      font-size: 16px;
      line-height: 1.8;

      &.stretch-slot {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .card-link {
        &.grow {
          flex-grow: 1;
        }

        .card-link-list {
          list-style: none;
          padding-left: 0;
          margin-top: 18px;
          margin-bottom: 0;
          margin-right: 0;

          .list-item {
            .list-item-link {
              padding: 11px 0;

              .card-link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                color: $linkColor;
                font-weight: 400;
                font-size: 16px;
                transition: all .3s;

                &:hover {
                  padding-inline: 4px;
                  color: $secondaryColor;
                }

                span {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  flex: 1;
                  margin-right: 5px;
                }

                .chevron-right {
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    .cta {
      font-size: 16px;
      line-height: 1.8;

      a {
        display: block;
        margin: 26px 0 0 0;
        padding: 14px 12px;
        font-size: 15px;
        color: $cardButtonTextColor;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 12px;
        background-color: $colorRed;
        transition: background .3s ease-out;

        &:hover {
          transition: 0.1s;
          background-color: $colorBlue;
        }
      }
    }
  }
}
