/* Contains variables, mixins, breakpoints, etc. Does NOT generate css output directly */
//--------------------------
// Variables
//--------------------------
$cardBorderRadius: 8px;

$breakpoint: 900px; // should match with values in store/modules/device.js
$phoneDesignWidth: 378px;
$tabletPortrait: 767px;
$desktopDesignWidth: 1100px; // (Design is 1440px, but stop scaling at 1100px)

//--------------------------
// Mixins & functions
//--------------------------
@mixin tablet-portrait() {
  @media (max-width: #{$tabletPortrait - 1px}) {
    @content;
  }
}
@mixin phone-small() {
  @media (max-width: #{$phoneDesignWidth - 1px}) {
    @content;
  }
}
@mixin phone() {
  @media (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}
@mixin desktop() {
  @media (min-width: #{$desktopDesignWidth}) {
    @content;
  }
}

@function fluid-scale($minScreenSize, $minSize, $maxScreenSize, $maxSize) {
  $base: #{$minSize - ($minScreenSize * ($maxSize - $minSize) / ($maxScreenSize - $minScreenSize))};
  $grow: #{(($maxSize - $minSize) / ($maxScreenSize - $minScreenSize)) * 100vw};
  @return calc(#{$base} + #{$grow});
}
// 378px;
@function fluid-scale-from-design($phone, $desktop) {
  @return fluid-scale($phoneDesignWidth, $phone, $desktopDesignWidth, $desktop);
}

@mixin fluid-from-design($cssProperty, $phone, $desktop) {
  #{$cssProperty}: fluid-scale-from-design($phone, $desktop);
  @media (max-width: $phoneDesignWidth) {
    #{$cssProperty}: $phone;
  }
  @media (min-width: $desktopDesignWidth) {
    #{$cssProperty}: $desktop;
  }
}

//--------------------------
// Abstract classes
//--------------------------

@mixin imageRadius {
  border-bottom-left-radius: 40px;
}
@mixin imageRadiusReversed {
  border-bottom-right-radius: 40px;
}
