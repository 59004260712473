.quick-menu {
  position: fixed;
  right: 0;
  z-index: 10;
  top: 50%;
  transition: all 0.2s ease-in-out;
  transform: translateX(calc(100% - 46px)) translateY(-50%);
  display: flex;
  flex-direction: column;
  box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.4);

  @include on-event {
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%);
  }

  &__item {
    background-color: $quickMenuBackgroundColor;
    padding: 0 16px;
    color: $quickMenuTextColor;
    border-bottom: 1px solid $quickMenuBorderColor;
    display: flex;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    @include on-event {
      background-color: $quickMenuBackgroundColorHover;
      color: $quickMenuTextColor;
      text-decoration: none;
    }

    span {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &__link {
      padding: 20px 0;
      color: $quickMenuTextColor;
      text-decoration: none;
      display: block;
      width: 100%;
    }
  }
}
