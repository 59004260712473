// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.home__news {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px;
  background-image: $bannerBackgroundGradient;

  @include tablet {
    background: none;
  }
}
