.link {
  color: $secondaryColor;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
  display: flex;
  align-items: center;

  .fa-chevron-right {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    transition: 200ms ease-in-out all;
    font-size: 16px;
    line-height: 18px;
  }

  &:hover {
    outline: none;
    text-decoration: none;

    .fa-chevron-right {
      transition: 200ms ease-in-out all;
      transform: translateX(6px);
    }
  }

  span {
    margin-left: 12px;
  }
}
