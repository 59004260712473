.block--toggle-list{
  display: flex;
  flex-direction: column;
  width: 100%;
  .block__list-item{
    width: 100%;
    border-bottom: 1px solid #d7dfe2;
    &:last-child {
        border-bottom: 0;
    }
  }
  .item__header{
    padding: 16px 16px 16px 0;
    margin: 0;
    cursor: pointer;
    background: #fff;
    color: $secondaryColor;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .chev-bottom {
      width: 16px;
      color: $primaryColor;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transform: rotate(90deg);
      transition: all 0.5s;
    }
    h3 {
      margin: 0;
      width: calc(100% - 24px - 16px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    @include on-event{
      .chev-bottom,
        h3 {
          color: $secondaryColor;
        }
    }
    &.active {
      .chev-bottom,
      h3 {
        color: $secondaryColor;
      }
      .chev-bottom {
        transform: rotate(-90deg);
      }
    }
  }
  .item__content__container{
    padding: 0 calc(16px + 16px) 32px;

  }
  .item__content{
    display: block;
    position: relative;
    max-height: 250px;
    overflow: hidden;
    &:after {
      transition: all .5s;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      z-index: 1;
      background-image: linear-gradient( 180deg ,hsla(0,0%,100%,0),#fff);
      content: "";
      display: block;
    }
  }
  .item__link{}
}