.videoBlock-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.videoBlock-block {
  .card-content {
    width: 100%;

    video {
      width: 100%;
    }
  }
}
