.back-link {
  color: $primaryColor;
  text-decoration: none;
  font-size: 18px;
  margin: 12px 0 12px 12px;
  display: flex;
  align-items: center;
  &.right{
    flex-direction: row-reverse;
  }

  .fa-chevron-left, .fa-chevron-right {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    transition: 200ms ease-in-out all;
    // transform: rotate(180deg);
  }

  &:hover {
    outline: none;
    text-decoration: none;
    color: $secondaryColor;
  }

  span {
    margin-left: 12px;
  }
}
