// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.navigation-desktop {
  .navigation-top {
    display: flex;
    align-content: center;
    gap: 1.5rem;
  }
}
