section.documents-tools {
  padding: 8rem 0 4rem;

  @media(max-width: 900px) {
    padding: 5rem 0;
  }

  [class*="col-"] {
    background-color: #DAE8EF;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .text {
    margin-bottom: 3rem;

    p {
      color: $colorBlue;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .button-white {
      background-color: #fff;
      border-radius: 999px;
      padding: 1rem 1.5rem;
    }
  }

}
