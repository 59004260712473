section.membership-profits {
  background-color: #E8F0F4;
  padding: 10rem 0;

  @media(max-width: 900px) {
    padding: 7rem 0;
  }

  h2 {
    font-size: 2.5rem;
    max-width: 50rem;
    margin-inline: auto;
    text-align: center;

    @media(max-width: 900px) {
      font-size: 2rem;
    }
  }

  [class*="col-"] {
    box-shadow: none;
    padding: 0;
    background-color: transparent;
  }

  .items {
    > *:not(.title-text) {
      display: block;
      border-bottom: 2px solid #fff;
      padding: 2.5rem 0
    }

    div {
      font-size: 1.125rem;
      font-weight: 500;
      text-decoration: none;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;

      &:hover {
        color: #004E90;

        svg {
          opacity: 1;
        }
      }

      svg {
        transition: all 0.3s;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0;
      }
    }

    .title-text {
      h3 {
        font-size: 1.25rem;
      }

      p {
        font-size: 1.125rem;
        font-weight: 400;
        color: #004E90;
        margin-bottom: 0;
      }
    }
  }

  .lobby-news {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #BDD1DB;

    a.link {
      margin: 0;
      padding: 1rem;
      border-radius: 8px;
      border: 1px solid #BDD1DB;
      color: #0470CC;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      transition: all .3s;

      &:hover {
        padding-inline: 22px !important;
        color: $colorRed;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .text {
      padding: 1.5rem 1rem;
      background-color: #DAE8EF;
      color: #004E90;
      line-height: 1.5;
      border-radius: 8px;
    }

    .link-card {
      position: relative;
      border-radius: 8px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      aspect-ratio: 2/1;
      overflow: clip;
      margin-top: 1rem;

      &:hover {
        text-decoration: none;
      }

      .content {
        position: relative;
        padding: 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(0deg, rgba(0, 0, 0, .3), transparent);

        .title {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .date {
          font-weight: 500;
        }
      }

      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        inset: 0;
      }
    }
  }
}
