.linkBlockList {
  .block--linkBlockList {
    height: 100%;
  }
  a {
    &:hover,
    &:focus {
      text-decoration: none !important;
      i {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  .card-header {
    height: 100%;
  }

  .card-img-container {
    margin-bottom: 12px;
  }
  .card-title {
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  i {
    display: inline-block;
    color: $secondaryColor;
    margin-left: 7px;
    margin-right: 3px;
    transition: margin 0.2s;
  }
}
