section.link-blocks {
  background-color: #DAE8EF;
  padding: 5rem 0;

  @media(max-width: 900px) {
    padding: 3rem 0;
  }

  .linkBlock {
    box-shadow: none;

    .card-content .card-header .card-title {
      font-size: 2rem;
    }

    .card-content .card-header .card-body {
      color: #004e90;
    }
  }
}
