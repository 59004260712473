.download-button {
  font-size: 16px;
  line-height: 1.8;

  a {
    display: block;
    margin: 26px 0 0 0;
    padding: 16px 32px 16px 12px;
    font-size: 15px;
    line-height: 1;
    color: $cardButtonTextColor;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 12px;
    background-image: $cardButtonBackgroundGradient;
    position: relative;

    @include on-event() {
      transition: 0.1s;
      background-image: $cardButtonBackgroundHoverGradient;
      color: $cardButtonTextColor;
    }

    i {
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
