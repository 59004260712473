form {
  // GRID STYLING
  .freeform-column {
    flex: 1 0;
    margin: 0 !important;
    box-sizing: border-box;

    & + .freeform-column {
      padding-left: 10px !important;
    }
  }

  .freeform-row {
    flex: 1 0;
    margin: 0 0 8px 0 !important;
    box-sizing: border-box;
  }

  // LABEL STYLING
  label,
  th {
    font-size: 14px;
    font-weight: normal !important;
    color: $formLabelTextColor;
    padding-bottom: 10px;
  }

  // INSTRUCTIONS STYLING
  .freeform-instructions {
    margin: 0 0 5px;
    font-size: 13px;
    color: $formInstructionsTextColor;
  }

  // INPUT, SELECT AND TEXTAREA STYLING
  input[type="text"],
  input[type="number"],
  input[type="phone"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  select,
  textarea {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    padding: 0 14px;
    margin-bottom: 12px;
    background-color: $inputFieldsBackgroundColor;
    border: 1px solid $inputFieldsBorderColor;
    outline: none;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: $inputFieldTextColor;
    font-family: "Ubuntu", "Open Sans", sans-serif;
    appearance: none;
  }

  textarea {
    padding: 12px;
    height: 120px;
    resize: none;
  }

  select[multiple] {
    padding: 0;
    height: auto;
    resize: none;
  }

  // RATING FIELD STYLING
  .form-rating-field-wrapper {
    display: flex;
    flex-direction: row-reverse;
    float: none;

    label {
      float: none;
    }
  }

  // LINK STYLING
  .freeform-column {
    a {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  // BUTTON STYLING
  button[type="button"],
  button[type="submit"],
  button[type="file"] {
    display: inline-block;
    margin: 0;
    padding: 16px 12px;
    font-size: 15px;
    color: $formButtonTextColor;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 12px;
    background-image: $formButtonBackgroundGradient;
    border: none;

    @include on-event {
      transition: 0.1s;
      background-image: $formButtonBackgroundHoverGradient;
      color: $formButtonTextColor;
    }
  }

  // TABLE OVERWRITES
  td {
    vertical-align: middle !important;

    input {
      margin-bottom: 0 !important;
    }
  }
}
