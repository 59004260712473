
.title-text > .list-item-content > .content > .list-item-title {
  margin-top: 2.5rem;
}

.list-item-content {
  cursor: pointer;

  .content {
    .list-item-title {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.progress-bar {
  margin-top: 2.5rem;
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 9999px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #01508a;
  transition: all 0.1s;
}
