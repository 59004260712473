// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden; // fixes carousel
  box-sizing: border-box;
}

body {
  overflow: auto;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $linkColor;
  text-decoration: none;

  @include on-event {
    text-decoration: underline;
    text-underline-position: under;
  }
}

button {
  width: auto;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  border-radius: 0;
  cursor: pointer;
}

ul,
ol,
li {
  list-style-type: none;
  padding: 0;
}

img {
  max-width: 100%;
}

.textBlock {
  figure {
    max-width: calc(100% - 2 * 20px) !important;
    margin: 20px !important;
  }

  figure img {
    max-width: 100%;
    height: auto;
  }

  @for $i from 1 through 12 {
    .col-#{$i},
    .col#{$i} {
      padding: 0;
      background: 0;
      box-shadow: none;
    }
  }
}

.hero-general {
  margin-top: 80px;
  padding-top: 3.5rem;
  display: flex;
  justify-content: center;

  h1 {
    font-family: inherit;
  }

  @media (min-width: $desktopDesignWidth) {
    margin-top: 150px;

    h1 {
      font-size: 56px;
    }
  }
}

.featured-post {
  background: #DAE8EF;
  padding: 50px 0;
  display: flex;
  justify-content: center;

  .title {
    font-size: 2rem;
    @media (min-width: $desktopDesignWidth) {
      font-size: 2.5rem;
    }
  }
  @media (min-width: $desktopDesignWidth) {
    padding: 100px 0;
  }

 .featured-card {
    background-color: white;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    gap: 40px;
    width: 80%;
   @media (max-width: $desktopDesignWidth) {
     flex-direction: column;
   }

   .card-content {
    position: relative;
    width: 100%;
     @media (min-width: $desktopDesignWidth) {
       width: 50%;
     }

     .benefit-badge {
       position: absolute;
       background-color: $primaryColor;
       top: 10px;
       left: 10px;
       border-radius: 25px;
       color: #fff;
       padding: 15px;
     }

     .link-icon {
       font-size: 18px;
     }
   }

   img {
     border-radius: 12px;
   }

   .badge {
     background: $primaryColor;
     color: white;
     border-radius: 100px;
     padding: 16px 24px;
   }
  }
}

.membership-types {
  background: #DAE8EF;
  padding: 30px 0;

  @media (min-width: $desktopDesignWidth) {
    padding: 60px 0;
  }
}

.membership-featured-box {
  border: 1px solid $cardBorderColor;
  padding: 24px;
  flex-direction: column;
  border-radius: 8px;
  display: flex;
  gap: 20px;

  .links {
    a {
      width: 100%;
    }
  }
}

.max-w-md {
  max-width: 550px !important;
}

.membership-featured {
  margin-bottom: 2rem;
  .row {
    justify-content: space-between;
  }

  h2 {
    color: black;
  }

  .col {
    background-color: transparent;
    box-shadow: none;
  }

  .featured-list {
    background: #DAE8EF;
    border-radius: 8px;
    padding: 24px;

    .label {
      background-color: white;
      padding: 12px 16px;
      border-radius: 100px;
      display: inline-flex;
      width: fit-content;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: $primaryColor;
    }

    ul {
      padding-top: 1rem;
      margin-bottom: 0;
      border-top: 1px solid $cardBorderColor;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    li {
      color: black;
      border-bottom: 1px solid $cardBorderColor;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 15px;

      i {
        color: $primaryColor;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.flex {
  display: flex;
  gap: 20px;
  @media (max-width: $desktopDesignWidth) {
    flex-direction: column;
  }
}

.col-half {
  width: 100%;
  @media (min-width: $desktopDesignWidth) {
    width: 50%;
  }
}

.expander {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 300ms ease;
}
.expander-content {
  min-height: 0;
  transition: all 300ms ease;
  visibility: hidden;
  margin-top: 0;
  border-top: 1px solid transparent;

  ul, ol, li {
    margin: 0;
    list-style-type: revert;
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      display: list-item;
      list-style-position: inside;
      border-bottom: 1px solid $cardBorderColor;
      padding-bottom: 10px;

      .list-price {
        font-weight: 500;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.expander.expanded {
  grid-template-rows: 1fr;
}
.expander.expanded .expander-content {
  visibility: visible;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid $cardBorderColor;
}

.expander-toggle {
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 10px;
  padding: 16px;
  margin: -16px;
  width: 100%;
  position: relative;
}

.expander-wrapper {
  background-color: #F6F6F6;
  border: 1px solid $cardBorderColor;
  padding: 16px;
  border-radius: 8px;
}
