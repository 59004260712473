.detailBlock {
  height: 100%;
  overflow: hidden;
  color: black;

  li {
    color: black;
  }

  &--full {
    height: unset;
  }

  .cta + .link {
    margin: 0;
    justify-content: space-between;
  }

  .cta a {
    margin: 0;
    display: inline-block;
  }

  .links {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;


    .button-primary {
      flex: none;
    }
  }

  .price {
    &-container {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
    }
    &-amount {
      font-weight: 500;
      font-size: 32px;
    }
    &-text {
      font-size: 14px;
    }
  }

  .detailBlock-block {
    height: 100%;
    z-index: 1;
    background-color: white;

    display: flex;
    flex-direction: column;

    .card-img-container {
      height: 190px;
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 6px 6px 0 0;

      .card-img-container__img {
        position: relative;
        transition: all 0.2s ease-in-out;

        &:hover {
          transition: all 0.2s ease-in-out;
          transform: scale(1.05);
        }

        .card-img-container {
          @include on-event {
            .card-img-container__img {
              transition: all 0.2s ease-in-out;
              transform: scale(1.05);
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .card-content {
      padding: 30px 30px 20px 30px;
      display: flex;
      gap: 20px 0;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      flex: 1;

      .card-header {
        &.without-link {
          height: 100%;
        }
        .meta {
          font-size: small;
          color: $secondaryTextColor;
          line-height: 1.8;
        }
        .card-header-link {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          &:hover,
          &:focus {
            text-decoration: none !important;
            i {
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
        .card-title {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 500;
          color: inherit;
          margin: 0;
          hyphens: auto;
        }
        i {
          display: inline-block;
          color: $secondaryColor;
          margin-left: 7px;
          margin-right: 3px;
          transition: margin 0.2s;
        }
      }

      .card-slot {
        display: flex;
        flex-direction: column;
        flex: 1;

        .detailBlock__text {
          line-height: 28px;
        }

        .card-usp-list {
          list-style: none;
          padding: 0;
          padding: 4px 0;
          margin-bottom: 20px;

          li {
            padding: 6px 12px 6px 0;
            color: #004e90;
            display: flex;
            align-items: flex-start;

            i {
              font-size: 24px;
              margin-top: 3px;
            }

            span {
              display: block;
              margin-left: 12px;
              color: $primaryTextColor;
              hyphens: auto;
            }
          }
        }
      }
    }
  }

  .detail-checks {
    list-style: none;
    margin: 20px 0 0 0;
    $b: solid 1px $cardBorderColor;
    border-top: $b;

    li {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid $cardBorderColor;

      &:last-child {
        border-bottom: 0;
      }
    }
    i {
      margin-top: 5px; //align with charachters
      flex: 0 0 34px;
      color: $primaryColor;
    }
  }
}

.link-icon {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  transition: all 300ms ease;

  &:hover {
    text-decoration: unset;
    padding: 0 10px;
  }
}
