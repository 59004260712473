// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin when-after($context) {
  #{$context} + & {
    @content;
  }
}

@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin limit-lines($amount) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}




// OLD STYLLING
@mixin news-block-gutters() {
  margin-bottom: 30px;
  @include phone {
    margin-bottom: 0;
  }
  &-title {
    margin-bottom: 12px;
    @include phone {
      @include fluid-from-design(font-size, 20px, 36px);
    }
  }
}

// other mixins
@mixin intro-negative-margin() {
  margin-top: -80px;

  @include phone {
    margin-top: 20px;
  }
}

@mixin videoFrame() {
  width: 100% !important;
  min-height: 420px !important;
  max-width: 100% !important;
  max-height: 47vw !important;
  border: 0;
  @include phone {
    min-height: auto !important;
    height: 48vw !important;
  }
}
