.contact-info {
  max-width: 100%;
  margin: 30px 0 0 0;
  border: 0;
  padding: 0;

  @include desktop {
    margin: 0;
    padding: 60px 0 0 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-title {
    font-size: 20px;
    margin: 0;
  }

  &-intro {
    font-size: 15px;
  }

  &-list {
    list-style: none;
    padding-left: 0;
    line-height: 2;

    &-item {
      margin-bottom: 10px;
      color: #004e90;
      display: flex;
      align-items: center;

      i {
        margin-right: 16px;
      }

      a {
        text-decoration: none;
        font-size: 16px;
        color: #004e90;
      }
    }
  }
}
