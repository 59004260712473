.search-results__spinner-container {
  display: flex;
  justify-content: center;
}
.search-results__nav {
  margin: 16px 0 0;
  * {
    color: $primaryTextColor;
  }
  ol {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    font-size: 18px;
    line-height: 1.1;
    justify-content: center;
  }
  button, .is-current-page {
    padding: 0 12px;
    height: 32px;
    border: none;
    background: none;
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
.search-results__list {
  list-style: none;
  padding-left: 0;
}
.search-results__nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  &:first-child {
    // padding-left: 0;
  }
  &:last-child {
    // padding-right: 0;
  }
  @include phone {
    padding: 0 8px;
    font-size: 15px;
  }
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  * {
    cursor: pointer;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  &.is-current-page {
    color: $secondaryColor;
    cursor: default ;
    &:hover{
      background: none;
    }
  }
}
.search-results-items {
  display: flex;
  flex-direction: column;
  .spring-spinner {
    .spring-spinner-rotator {
      border-top-color: #004e90 !important;
      border-right-color: #004e90 !important;
    }
  }
}
.search-results-navigation {
  display: flex;
  button {
    background: none;
    border: solid 1px black;
  }
  .search-nav-spacer {
    flex: 1;
    border-bottom: solid 1px black;
  }
}
.spinner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  position: relative;
}
@keyframes resultFadeIn {
  from {
    opacity: 0;
    background: #f2f2f2;
  }
  to {
    opacity: 1;
    background: #fff;
  }
}
@for $i from 1 through 10 {
  .search-result:nth-of-type(#{$i}) {
    animation-delay: #{$i / 10}s;
  }
}
.search-result {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  border-bottom: 1px solid #f2f2f2;
  font-size: 14px;
  text-decoration: none !important;
  color: #737373;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0;
  animation: resultFadeIn 1s forwards;
  time {
    color: $primaryTextColor !important;
  }
  &:hover {
    &:before {
      //Regular background doesn't work because of the forwards state of the animation
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.02);
      position: absolute;
    }
  }
  &__tab-container {
    border-bottom: 1px solid #dadada;
    margin: 12px 0 0 0;
    position: relative;
  }
  &__tab {
    border: none;
    background: none;
    display: inline-block;
    padding: 20px 20px 20px;
    border-right: 1px solid #dadada;
    border-top: 1px solid #dadada;
    position: relative;
    text-decoration: none;
    color: $primaryColor;
    box-sizing: border-box;
    &:nth-of-type(1) {
      border-left: 1px solid #dadada;
    }
    &:hover{
      color: $secondaryColor;
      cursor: pointer;
    }
    &.active {
      font-weight: bold;
      color: $secondaryColor;
      &:after {
        content: '';
        width: 100%;
        display: block;
        background-color: white;
        left: 0;
        height: 3px;
        bottom: -1px;
        position: absolute;
      }
    }
    @include phone-small {
      width: 100%;
      border-left: 1px solid #dadada;
      &:nth-of-type(2) {
        border-bottom: 1px solid #dadada;
      }
    }
  }
  p {
    max-width: 750px;
    margin: 0;
    p {
      margin: 0;
    }
  }
  h3 {
    margin: 0 0 12px;
    padding-right: 30px;
    @include phone {
      margin: 0 0 16px;
      padding-right: 0;
    }
  }
  .chevron-right {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    @include phone {
      display: none;
    }
  }
  .search-result__uri {
    position: relative;
    display: flex;
    align-items: center;
    color: #aaa;
    margin: 0 0 16px;
    @include phone {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        width: 30px;
        right: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .search-result__icon {
    height: 18px;
    width: 18px;
    margin: 0 8px 0 0;
  }
}
.search-result-info {
  margin: 0;
  padding: 12px 0 20px 16px;
  border-bottom: 1px solid #f2f2f2;
  font-size: 14px;
}
.search-results-items__none-warn {
  margin: 32px 0 0 16px;
  line-height: 150%;
  @include phone {
    margin: 16px 0 0;
  }
}
