section.homepage-hero {
  position: relative;
  background-color: $linkColor;
  padding: 3.5rem 0;
  margin-top: 150px;
  line-height: 0;

  @media(max-width: 1100px) {
    margin-top: 80px;
  }

  @media(max-width: 992px) {
    padding-top: 3rem;
  }

  @media(max-width: 900px) {
    padding-bottom: 0;
  }

  .hero-content {
    line-height: 1;

    h1 {
      color: #fff;
      font-size: 3.5rem;
      font-family: "Ubuntu", "Open Sans", sans-serif;
      margin-bottom: 2.5rem;
      line-height: 1.3;

      @media(max-width: 992px) {
        font-size: 2rem;
      }
    }

    h3 {
      color: #fff;
      font-weight: 500;
    }

    .search-wrapper {
      input {
        width: 100%;
      }
    }
  }

  .hero-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40vw;
    object-fit: cover;

    @media(max-width: 900px) {
      position: relative;
      width: 100%;
      aspect-ratio: 3/2;
    }
  }
}
