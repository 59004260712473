.card {
  &--link {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include on-event {
      text-decoration: none;
    }
  }

  &--image-container {
    min-height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--image {
    max-width: 50px;
    max-height: 50px;
  }

  &--title {
    text-decoration: none;
    color: $primaryColor;
    font-weight: 700;
    word-break: break-word;
    text-align: center;
    margin: 16px 0 0 0;
  }
}
