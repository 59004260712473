.newsItem {
  height: 100%;
  overflow: hidden;

  .newsItem-block {
    height: 100%;
    z-index: 1;
    background-color: white;
    display: flex;
    flex-direction: column;

    .card-img-container {
      height: 190px;
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 6px 6px 0 0;

      .card-img-container__img {
        position: relative;
        transition: all 0.2s ease-in-out;

        &:hover {
          transition: all 0.2s ease-in-out;
          transform: scale(1.05);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .card-content {
      padding: 30px 30px 20px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      flex: 1;

      .card-slot {
        display: flex;
        flex-direction: column;
        flex: 1;

        .newsItem__meta {
          span {
            font-size: 13px;
          }

          &-date {
            border-right: 1px solid #c4c4c4;
            padding-right: 7.5px;
            margin-right: 7.5px;
          }

          &-tag {
            color: $primaryColor;

            &:not(:last-child):after {
              content: ",";
              margin-left: -2px;
            }
          }
        }

        .newsItem__text {
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }

      .link {
        appearance: none;
        background: none;
        border: none;
      }
    }
  }
}
