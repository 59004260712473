// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

$colorWhite: #ffffff;
$colorBlackDefault: #000000;
$colorBlack: #2d2d2d;
$colorRed: #cc0033;
$colorBlue: #004e90;
$colorBlue75: #4270a4;
$colorBlue50: #7fa6c7;
$colorBlue25: #e6e9f3;

$borderRadius: 8px;

$fontWeightNormal: 400;
$fontWeightSemibold: 500;
$fontWeightBold: 700;

$zIndexModal: 15;
$zIndexNavigation: 20;
