.teaserBlock {
  &.blue {
    background: $cardBlueBackgroundColor;

    & * {
      color: $cardBlueTextColor;
    }

    .card-content .card-slot a{
      @include on-event{
        color: $secondaryColor;
        text-decoration: underline;
      }
    }
  }
  &.white{
    .card-content .card-slot a{
      @include on-event{
        color: $secondaryColor;
        text-decoration: underline;
      }
    }
  }

  &.white {
    height: unset;
  }

  .card-img-container {
    height: 190px;
    overflow: hidden;
    position: relative;
    border-radius: 6px 6px 0 0;
    width: calc(100% + 4em);
    margin-left: -2em;
    margin-top: -2em;
    margin-bottom: 27px;

    .card-img-container__img {
      position: relative;
      transition: all 0.2s ease-in-out;

      @include on-event {
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .block--teaserBlock,
  .block--content,
  .card-content {
    height: 100%;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    .card-header {
      display: flex;
      justify-content: space-between;

      .card-title {
        width: 100%;
        margin: 0 0 12px;
        display: flex;
        font-size: 20px;

        @include tablet {
          font-size: 24px;
        }
      }
    }

    .card-slot {
      font-size: 16px;
      line-height: 1.8;

      &.stretch-slot {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      h2 {
        hyphens: auto;
      }

      a {
        cursor: pointer;
        text-decoration: underline;

        &.link {
          text-decoration: none;
        }

        @include on-event {
          color: $cardBlueTextColor;
        }
      }
    }
  }
}
