.featured-column {
  @include until-desktop {
    width: 100%;
    flex-basis: 100%;
  }
}

.block--featuredNewsItem {
  z-index: 1;
  display: flex;
  background: white;
  border-radius: $cardBorderRadius;
  overflow: hidden;
  flex-direction: row-reverse;

  @include phone {
    flex-direction: column-reverse;
  }

  .block-title {
    a {
      text-decoration: none;
      color: $primaryColor;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .block-body {
    padding: 3em;
    flex: 1;
  }

  .block-copy {
    padding-bottom: 16px;
  }

  .img-container {
    position: relative;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include imageRadiusReversed;
    flex: 1;

    @include phone {
      height: 40vw; //this doesn't work because FLEX but hey it's on live aswell.
    }
    // OLD: Transparent image overlay, to allow copy image
    .img-container__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
  .link {
    margin-top: 1em;
    text-decoration: none;
    color: $secondaryColor;
    display: block;
    &:hover {
      color: $primaryColor;
    }
  }
}
