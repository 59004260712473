.personBlock {
  .card-img-container {
    height: 270px;
    width: 100%;
    overflow: hidden;
    position: relative;

    .card-img-container__img {
      position: relative;
      transition: all 0.2s ease-in-out;

      img {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 30px 30px 24px 30px;

    .card-header {
      display: flex;
      justify-content: space-between;

      .card-title {
        width: 100%;
        margin: 0 0 12px;
        display: flex;
        font-size: 24px;

        @include tablet {
          font-size: 25px;
        }
      }
    }

    .card__slot {
      font-size: 16px;
      line-height: 1.8;

      &.stretch-slot {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      p {
        line-height: 28px;
        color: $primaryTextColor;
      }

      .phone,
      .email {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
