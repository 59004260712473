// Navigation Search Bar
.search-bar {
  position: relative;
  width: 100%;
  max-width: 450px;
  cursor: pointer;

  .search-bar-input {
    width: 100%;
    border-radius: $borderRadius;
    background: $colorBlue25;
    border: 0;
    padding: 16px;
    padding-left: 45px;
    pointer-events: none;
  }

  .search-bar-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 10px;
    transform: translateY(-50%);
  }

  ::placeholder {
    color: $colorBlue75;
  }
}
