// BASE GRID LAYOUT STYLING
.grid-container {
  max-width: 1200px;
  margin: 40px auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  @include tablet {
    margin: 40px auto;
  }
  @include desktop {
    margin: 40px auto;
  }
}

// DISTANCES, POSITIONS ETC.
