// Primary Color Palette
$primaryColor: #004e90;
$boldfontColor: #02559f;

// Secondary Color Palette
$secondaryColor: #db1546;

// Inputs
$inputBorderColor: #c2d1d9;

// Text Colors
$primaryTextColor: #737373;
$secondaryTextColor: #c4c4c4;

// Links
$linkColor: #0470cc;
$linkColorSecondary: #42a2e5;

// Cards
$cardBackgroundColor: #ffffff;
$cardBackgroundGradient: linear-gradient(-216deg, #eff5f8 0%, #dae8ef 100%);
$cardBorderColor: lightgrey;
$sectionBorderColor: #cde2f5;

$cardButtonTextColor: #ffffff;
$cardButtonBackgroundGradient: linear-gradient(-180deg, #db1546, #b2125d 89%);
$cardButtonBackgroundHoverGradient: linear-gradient(
  -180deg,
  #a21437,
  #7b0e40 89%
);

// Cards - blue
$cardBlueBackgroundColor: $primaryColor;
$cardBlueTextColor: #ffffff;
$cardBlueTextHoverColor: #d3d3d3;
$cardBlueBorderColor: #d3d3d3;
$cardBlueSecondaryColor: #3ea6d8;

// Banner
$bannerTextColor: $primaryColor;
$bannerBackgroundGradient: linear-gradient(-216deg, #eff5f8, #dae8ef);
$bannerLineColor: #ffffff;
$bannerSphereColor: #ffffff;

// Form
$formLabelTextColor: $primaryTextColor;
$formInstructionsTextColor: #aba7a7;
$inputFieldsBackgroundColor: #ffffff;
$inputFieldsBorderColor: #d0d0d0;
$inputFieldTextColor: #000000;
$formButtonTextColor: $cardButtonTextColor;
$formButtonBackgroundGradient: $cardButtonBackgroundGradient;
$formButtonBackgroundHoverGradient: $cardButtonBackgroundHoverGradient;
$formButtonTextColor: $cardButtonTextColor;

// QuickMenu
$quickMenuBackgroundColor: $primaryColor;
$quickMenuBackgroundColorHover: #02559f;
$quickMenuTextColor: #ffffff;
$quickMenuBorderColor: #ffffff;
