section.accomplishments {
  padding: 10rem 0;

  @media(max-width: 900px) {
    padding: 5rem 0;
  }

  [class*="col-"] {
    box-shadow: none;
    padding: 0;
    justify-content: center;
    height: auto;
  }

  .desktop-col {
    @media(max-width: 900px) {
      display: none;
    }
  }

  .mobile-row {
    padding: 2rem 0;

    @media(min-width: 900px) {
      display: none;
    }

    .glide__slides {
      overflow: visible;

      .glide__slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .rotate-left {
      transform: rotate(-16deg);
    }

    .rotate-right {
      transform: rotate(16deg);
    }
  }

  p {
    color: $colorBlue;
    line-height: 1.45;
  }

  .accomplishments-top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 90px;

    .accomplishment {
      &:first-of-type {
        transform: rotate(16deg);
        margin-top: 3rem;
      }
      &:last-of-type {
        transform: rotate(-16deg);
        margin-top: 3rem;
      }
    }
  }

  .accomplishment-center {
    max-width: 25rem;
    text-align: center;
    margin-inline: auto;

    h2 {
      font-size: 2.5rem;

      @media(max-width: 900px) {
        font-size: 2rem;
      }
    }
  }

  .accomplishment-center-left {
    .accomplishment {
      margin-inline: auto;
      transform: rotate(-16deg);
    }
  }

  .accomplishment-center-right {
    .accomplishment {
      margin-inline: auto;
      transform: rotate(16deg);
    }
  }

  .accomplishments-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 90px;

    .accomplishment {
      &:first-of-type {
        transform: rotate(-16deg);
        margin-top: -3rem;
      }
      &:last-of-type {
        transform: rotate(16deg);
        margin-top: -3rem;
      }
    }
  }
}

.accomplishment {
  background-color: #E8F0F4;
  max-width: 12rem;
  padding: 1rem;
  border-radius: 0.5rem;

  a {
    text-decoration: none;
  }

  img {
    margin-inline: auto;
    width: 7.5rem;
    height: 7.5rem;
    max-width: 50%;
    object-fit: contain;
    display: block;
  }

  p {
    color: #004E90;
    margin: 0;
    font-size: .9rem;
  }
}
