// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any,
// and all typgraphic styling (H1 - H6, p, strong, em, i, blockquote)
// -----------------------------------------------------------------------------
//
// When preferred or needed, @font-face declarations can also be moved to
// separate _fonts.scss
// -----------------------------------------------------------------------------

// Primary Fonts
$primaryFont: 'Ubuntu', sans-serif;

body,
html {
  color: $primaryTextColor;
  font-family: $primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  font-weight: bold;
  color: $primaryColor;

  a {
    text-decoration: none;
    &:hover {
      color: $primaryColor;
    }
  }
}

h2,
.h2 {
  @include fluid-from-design(font-size, 20px, 26px);
}

.section-header {
  font-size: 36px;
  margin: 40px 18px 25px 18px;
}

h3,
.h3 {
  @include fluid-from-design(font-size, 16px, 20px);
}

h4,
h5 {
  font-size: 16px;
}

.no-margin {
  margin: 0;
}

p,
ul,
ol,
li {
  line-height: 28px;
  color: #737373;
}

strong {
  font-weight: bold;
  color: $boldfontColor;
}

a {
  color: $primaryColor;

  &:hover {
    color: $secondaryColor;
  }
}

hr {
  background-color: #dddddd;
  border: 0;
  height: 2px;
}

figure {
  margin: 20px 0 !important;
  iframe {
    @include videoFrame();
  }
}

.introBlock iframe {
  width: 100%;
  height: 400px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    th {
      border: none;
      & > * {
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      border: none;

      p {
        margin: 0;
        padding: 0;
      }
    }

    td {
      padding: 7px;
      vertical-align: top;
      border: none;
      font-size: 16px;
      line-height: 1.8;
    }
  }
}

em {
  font-style: italic;
}
.article-meta {
  color: grey;
  font-size: 16px;
  line-height: 1.8;
}

.fa,
.fab,
.fal,
.far,
.fas {
  font-family: 'Font Awesome 5 Brands';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// Font Weight
.t-normal {
  font-weight: $fontWeightNormal;
}
.t-semibold {
  font-weight: $fontWeightSemibold;
}
.t-bold {
  font-weight: $fontWeightBold;
}

.t-xs {
  font-family: $primaryFont;
  font-size: 12px;
  font-weight: $fontWeightNormal;
  line-height: 130%;
}

// Text
.t-sm {
  font-family: $primaryFont;
  font-size: 14px;
  font-weight: $fontWeightNormal;
  line-height: 130%;
}

.t-md {
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: $fontWeightNormal;
  line-height: 130%;
}

.t-lg {
  font-family: $primaryFont;
  font-size: 18px;
  font-weight: $fontWeightNormal;
  line-height: 130%;
}

// Buttons
.t-button-primary {
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: $fontWeightBold;
  line-height: 1;
}

.t-button-secondary {
  font-family: $primaryFont;
  font-size: 14px;
  font-weight: $fontWeightSemibold;
  line-height: 1;
}

// Navigation
.t-navigation {
  font-family: $primaryFont;
  font-size: 18px;
  font-weight: $fontWeightSemibold;
  line-height: 1;
}

.t-navigation-small {
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: $fontWeightNormal;
  line-height: 1;
}
