.col-adbutler {
  background: none;
  margin: 0 !important;
  box-shadow: none !important;
  &.margin-top{
    margin-top: 25px !important;
  }
}
.col-align-center{
  align-items: center;
}
.adButler--container{
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  margin: 0 auto 0;
  background: none;
  overflow: hidden;
  iframe {
    display: block;
    border: none;
    transform-origin: top left;
    width: 100%;
    height: 100%;
    &.align-left{
      margin: 0 auto 0 0;
    }
    &.align-center{
      margin: 0 auto;
    }
    &.align-right{
      margin: 0 0 0 auto;
    }
  }
}