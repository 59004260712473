.uspBlock,
.contactBanner {
  box-shadow: none;
  width: 100%;

  .block--content {
    .icon-grid {
      color: $primaryColor;
      padding: 50px 1em 62px;
      box-sizing: border-box;
      margin: 20px 0;

      @include tablet {
        margin: 40px 0;
      }

      @include desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &.hasBackground {
        background-image: $bannerBackgroundGradient;
      }

      .title-section {
        margin-bottom: 12px;

        @include desktop {
          margin: 0 0 3em 0;
        }

        .title {
          text-align: center;
          margin-bottom: 0.3em;
          font-weight: 700;
          font-size: 24px;

          @include tablet {
            font-size: 28px;
          }
        }

        .subtitle {
          margin-top: 0;
          text-align: center;
          font-weight: 400;
        }
      }

      .title-section {
        padding-left: 30px;
        padding-right: 30px;

        @include desktop {
          max-width: 1305px;
        }
      }

      .container {
        padding-left: 30px;
        padding-right: 30px;

        @include desktop {
          max-width: 1305px;
        }

        .icon-list {
          list-style: none;
          padding-left: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          line-height: 28px;
          color: $primaryTextColor;

          .icon-list-item {
            line-height: 28px;
            color: $primaryTextColor;
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            align-items: flex-start;
            margin-top: 0;
            padding: 16px 0px 10px;

            @include tablet {
              width: 200px;
              align-items: stretch;
              margin-top: 12px;
              padding: 16px 30px 10px;
            }

            &:after {
              content: "";
              left: -34px;
              right: -34px;
              height: 1px;
              background-color: $bannerLineColor;
              display: block;
              position: absolute;
              bottom: 0;

              @include tablet {
                display: none;
              }
            }

            .sphere {
              background: $bannerSphereColor;
              width: 90px;
              height: 90px;
              display: block;
              border-radius: 50%;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            a,
            .fake-link {
              color: $linkColor;
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              text-align: left;
              padding: 6px 20px;
              text-decoration: none;

              @include tablet {
                flex-direction: column;
                padding: 0;
                text-align: center;
              }

              .icon-img-container {
                width: 50px;
                margin-right: 20px;

                @include tablet {
                  margin-right: 0;
                  width: 100%;
                }

                @include desktop {
                  margin-bottom: 30px;
                }

                .icon-img {
                  width: 100%;
                  height: 90px;
                  transition: 0.25s ease-in-out;
                  object-fit: contain;

                  @include tablet {
                    width: 90px;
                  }

                  &:hover {
                    transform: scale(1.1);
                  }
                }
              }

              .icon-title {
                line-height: 22px;
              }

              .icon-body {
                margin-top: 0;
                font-size: 14px;
                line-height: 21px;
                color: $primaryColor;
                display: none;

                @include tablet {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

// overwrites for when uspBlock is used as contactBlock
.contactBanner {
  .block--content {
    .icon-grid {
      .container {
        .icon-list {
          .icon-list-item {
            align-items: center;

            &:after {
              display: none;
            }

            a,
            .fake-link {
              flex-direction: column;

              .icon-body {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
