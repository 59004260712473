$flexboxgrid-grid-columns: 12 !default;
// Set the gutter between columns.
$flexboxgrid-gutter-width: 18px !default;
// Set a margin for the container sides.
$flexboxgrid-outer-margin: 18px !default;

$gutter-compensation: $flexboxgrid-gutter-width * 0.5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * 0.5;

// Main Grid (span: 2 - 3)
.container {
  @include fluid-from-design(padding-left, 10px, 30px);
  @include fluid-from-design(padding-right, 10px, 30px);
  overflow-x: hidden;

  @media (min-width: 1270px) {
    padding-left: calc(45vw - 555px);
    padding-right: calc(45vw - 555px);
  }
  &--fill {
    min-height: 100%;
  }
}

// Flexbox (inside of .container (2 - 3))
@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;
  @include flex-grow(0);
  @include flex-shrink(0);
  padding: 0 $flexboxgrid-gutter-width;
}

.row-header {
  margin: 24px 0;
  font-size: 36px;
  @include when-inside('.hero-row + .row') {
    margin-top: -16px;
    @media (max-width: $desktopDesignWidth) {
      margin-top: 24px;
    }
  }
  &--margin-top {
    margin-top: 142px !important;
  }
  @media (max-width: $desktopDesignWidth) {
    margin-right: $flexboxgrid-outer-margin;
    margin-left: $flexboxgrid-outer-margin;
    font-size: 28px;
  }
}

.row {
  width: calc(100% + #{-$gutter-compensation * 2});
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin: 0 $gutter-compensation;
  z-index: 1;
  &--relative {
    position: relative;
  }

  &.full-width {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    display: block;
  }

  &.hero-row {
    z-index: 0;

    &.extra-margin {
      @include desktop {
        margin-bottom: 100px;
      }
    }
  }

  &.hero-row-spacing {
    @include desktop {
      margin-top: 108px;
    }
  }

  &.row-first {
    margin-top: 53px;
  }
  &.row-first-extra {
    margin-top: 93px;
  }

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section-subheader {
      margin-top: 40px;
      margin-bottom: 0;
    }

    .section-header {
      margin-top: 0;
    }
  }

  @include phone {
    &:not(.tablet-row) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  // exception for row behaviour on tablet
  &.tablet-row {
    @media (max-width: 1160px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media (max-width: 620px) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .col-3,
    .col3,
    .col-4,
    .col4 {
      @media (max-width: 1160px) {
        @include flex-basis(100% / 2);
        max-width: 50%;
      }
      @media (max-width: 620px) {
        max-width: 100%;
      }
      img {
        height: 100%;
      }
    }
  }

  &.full-height-row {
    .col {
      height: unset;
    }
  }
}

@for $i from 1 through $flexboxgrid-grid-columns {
  .col-#{$i},
  .col#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(
      calc(((100% / #{$flexboxgrid-grid-columns}) * #{$i}) - 2 * #{$flexboxgrid-gutter-width})
    );
    max-width: calc(
      ((100% / #{$flexboxgrid-grid-columns}) * #{$i}) - 2 * #{$flexboxgrid-gutter-width}
    );
    margin: 20px 18px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: $cardBorderRadius;
    padding: 2em;
    box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
    height: min-content;

    & > * {
      flex-shrink: 0;
    }

    &.container-column {
      background: none;
      box-shadow: none;
    }

    // only row for desktop, not mobile
    &.container-row {
      flex-direction: column;

      @include desktop {
        flex-direction: row;
      }
    }

    @if ($i < 4) {
      h2,
      h3,
      h4 {
        font-size: 18px !important;
      }
    }

    &.no-padding {
      padding: 0;
    }

    &.no-margin {
      margin: 0 0 25px 0;
      max-width: 100%;
    }
    &.margin-bottom-none {
      margin-bottom: 0;
    }

    @include phone() {
      max-width: none;
      min-width: calc((100%) - 2 * #{$flexboxgrid-gutter-width});
    }

    // only half for desktop, not mobile
    .half {
      width: 100%;

      @include desktop {
        width: 50%;
      }
    }
  }
}

.intro-section {
  @include intro-negative-margin;
  height: 100%;
}

.col-fit {
  width: 100%;
  max-width: 100%;
}

.col {
  z-index: 1;
}
