.textBlock {
  display: flex;
  flex-direction: column;
  position: relative;

  .card-title {
    width: 100%;
    margin: 12px 0 34px;
    display: flex;
    font-size: 26px;
    font-weight: 700;
    color: $primaryColor;
  }

  .intro-container {
    color: $primaryColor;

    p {
      color: $primaryColor;
      font-weight: 700;
    }
  }

  .intro-list {
    list-style: none;
    padding-left: 0;
  }

  .intro-title {
    margin-top: 0;
  }

  .intro-body {
    a {
      text-decoration: underline;
    }

    ul, ol {
      padding-left: 20px;
    }

    ul li {
      list-style: disc;
    }
    ol li {
      list-style: decimal;
    }

    .cta {
      a {
        text-decoration: none;
        width: fit-content;
      }
    }

    .link {
      text-decoration: none;
    }
  }

  .img {
    width: 100%;
  }

  .share-icons {
    margin-top: 40px;

    .share-icons__list {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 30px 0 0 0;

      a {
        font-size: 30px;
        margin-right: 20px;
      }
    }
  }
}
